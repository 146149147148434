<template>
  <NuxtLink
    :to="to"
    class="flex items-center gap-3 cursor-pointer mb-1 hover:bg-neutral-100 fill text-xsm py-2 relative overflow-hidden"
    :class="{
      'active rounded bg-neutral-100 text-primary-700': isActive,
      'text-typography-disabled': !isActive,
      'justify-center': collapsed,
      'px-4': !collapsed,
    }"
  >
    <div
      class="absolute left-0 h-full w-[2px]"
      :class="{
        'bg-primary-700': isActive,
        'bg-transparent': !isActive,
      }"
    />
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
const props = defineProps({
  active: {
    default: false,
    type: Boolean,
  },
  to: {
    default: null,
    type: String,
  },
});

const isActive = computed(() => {
  if (props.active) return true;

  const parts = useRoute().fullPath.split('/').slice(0, 4).join('');
  const matches = props.to?.split('/').slice(0, 4).join('');

  // If everything matches except the last piece then it should be active
  if (parts === matches) return true;

  return useRoute().fullPath.includes(props.to);
});

const collapsed = inject('collapsed', true);
</script>

<style lang="scss">
.fill {
  svg {
    fill: currentColor;
  }
}
</style>
